<template>
  <div>
    <v-row class="fill-height">
      <v-flex sm3 class="case-list">
        <v-list>
          <v-subheader class="header"> CASE CẦN ĐIỀU PHỐI </v-subheader>
          <v-col style="padding-top: 2px">
            <v-text-field
              label="Tìm kiếm theo mã case"
              solo
              dense
              v-model="searchText"
              @change="getCasesToDispatch"
            ></v-text-field>
            <div v-if="isViewFilter">
              <label style="margin-left: 5px">Chọn chi nhánh</label>
              <select
                id="branch"
                v-model="selectedBranch"
                class="dropdown-menu"
              >
                <option
                  v-for="item in branchList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
              <label class="arrow">&#11207;</label>
            </div>
          </v-col>
          <perfect-scrollbar>
            <v-list-item-group color="primary" v-model="selectedIndex">
              <v-list-item
                class="dispatch-item"
                three-line
                v-for="(item, i) in casesToDispatch"
                :key="i"
                @change="setSelectedCase(item)"
              >
                <v-list-item-content :title="item.title">
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="padding-top: 3px">
                    Mã Case: {{ item.code }} {{ item.caseStatusName }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="padding-top: 2px">
                    Loại: {{ item.caseTypeName }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="padding-top: 2px">
                    Thời gian yêu cầu:
                    {{ moment(item.requestTime).format(YYYYMMDDHHmm) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="padding-top: 2px">
                    Địa chỉ: {{ item.address }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </perfect-scrollbar>
        </v-list>
      </v-flex>
      <v-flex sm9 style="padding: 5px">
        <v-col>
          <v-sheet height="46">
            <v-toolbar dense flat>
              <span>Ngày điều phối </span>
              <v-toolbar-items>
                <v-btn text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on">
                      {{ selectedDate }}
                    </v-btn>
                  </template>
                  <v-row justify="center">
                    <v-card class="sc-date-picker">
                      <v-date-picker v-model="selectedDate"></v-date-picker>
                    </v-card>
                  </v-row>
                </v-menu>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>

                <!-- <v-menu>
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="white" v-bind="attrs" v-on="on">
										{{
											locationType == 1
												? "Tính theo vị trí nhà"
												: "Tính theo vị trí xe"
										}}
									</v-btn>
								</template>
								<v-row justify="center">
									<v-card class="sc-date-picker">
										<div class="sc-date-picker">
											<v-radio-group v-model="locationType">
												<v-radio
													label="Tính theo vị trí nhà"
													value="1"
												></v-radio>
												<v-radio
													label="Tính theo vị trí xe"
													value="2"
												></v-radio>
											</v-radio-group>
										</div>
									</v-card>
								</v-row>
							</v-menu> -->

                <v-btn
                  color="primary"
                  text
                  :loading="loadingTechnician"
                  @click="getEventByDate"
                >
                  Tìm kỹ thuật phù hợp
                </v-btn>
              </v-toolbar-items>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="806">
            <v-calendar
              ref="calendar"
              v-model="selectedDate"
              color="primary"
              type="category"
              category-show-all
              :categories="categories"
              :events="events"
              :event-color="getEventColor"
              first-time="07:00"
              interval-count="16"
              @click:time-category="categoryOnClick"
              :category-text="getCategoryText"
            ></v-calendar>
          </v-sheet>
        </v-col>
      </v-flex>
      <v-dialog v-model="loadingTechnician" hide-overlay persistent width="300">
        <v-card color="primary" dark class="loading-card">
          <v-card-text>
            Đang tải dữ liệu
            <p></p>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showDispatchDialog"
        persistent
        max-width="500"
        v-if="selectedCategoryEvent"
      >
        <v-card>
          <v-card-title class="text-h5">{{ selectedCase.title }}</v-card-title>
          <v-card-text>
            <form>
              <v-text-field
                :value="selectedCase.code"
                label="Mã case"
                readonly
              ></v-text-field>
              <v-text-field
                :value="selectedCase.caseTypeName"
                label="Loại"
                readonly
              ></v-text-field>
              <v-text-field
                :value="moment(selectedCase.requestTime).format(YYYYMMDDHHmm)"
                label="Thời gian khách hàng yêu cầu"
                readonly
              ></v-text-field>
              <v-text-field
                :value="selectedCase.address"
                label="Địa chỉ lắp đặt"
                readonly
              ></v-text-field>
              <v-text-field
                :value="selectedCategoryEvent.category"
                label="Nhân viên được điều phối"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="planTime"
                label="Thời gian được điều phối"
                required
              ></v-text-field>
            </form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showDispatchDialog = false"> Huỷ </v-btn>
            <v-btn
              color="primary"
              dark
              @click="assignCase"
              :loading="loadingAssign"
            >
              Điều phối
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-snackbar v-model="snackbar" :timeout="3000">
        {{ message }}
      </v-snackbar>
    </v-row>
  </div>
</template>

<script>
const YYYYMMDDHHmm = "YYYY-MM-DD HH:mm";
const YYYYMMDD = "YYYY-MM-DD";
export default {
  data: () => ({
    YYYYMMDDHHmm: YYYYMMDDHHmm,
    selectedDate: "",
    selectedIndex: undefined,
    casesToDispatch: [],
    selectedCase: null,
    selectedCategoryEvent: null,
    planTime: null,
    categories: [],
    events: [],
    technicians: [],
    loadingTechnician: false,
    showDispatchDialog: false,
    loadingAssign: false,
    locationType: 1,
    message: "",
    snackbar: false,
    LocationType: null,
    branch: "",
    profile: "",
    searchText: "",
    branchId: 0,
    branchList: [
      { value: "", label: "Tất cả" },
      { value: "HN", label: "Hà Nội" },
      { value: "DN", label: "Đà Nẵng" },
      { value: "HCM", label: "Hồ Chí Minh" },
    ],
    selectedBranch: null,
    isViewFilter: false,
  }),
  watch: {
    selectedBranch: {
      handler() {
        // this.selectedBranch
        console.log(
          "🚀 ~ file: CaseDispatch.vue:266 ~ handler ~ this.selectedBranch:",
          this.selectedBranch
        );
        this.getCasesToDispatch();
      },
    },
    isViewFilter: {
      handler() {},
    },
  },
  mounted() {
    this.setToday();
    this.getCasesToDispatch();
    const urlParams = this.$route.query.branch;
    this.branch = urlParams.branch;

    this.isViewFilter = this.$route.query.isViewNationwide
      ? JSON.parse(this.$route.query.isViewNationwide)
      : false;

    // console.log("now", this.moment().toDate());
  },
  created: {
    getprofile() {
      this.profile = this.$route.query;
      // console.log(this.profile);
    },
  },
  methods: {
    getCategoryText(technician) {
      return `${technician.technicianEmail.replace(
        "@vietmap.vn",
        ""
      )} (${this.getDistanceByTechnician(
        technician
      )}km-${this.getDurationByTechnician(technician)})`;
    },
    showMessage(mes) {
      this.message = mes;
      this.snackbar = true;
    },
    getColorByCaseStatus(c) {
      switch (c.caseStatusId) {
        case 1:
          return "red";
        case 2:
          return "green";
        case 3:
          return "purple";
        case 4:
          return "orange";
        case 5:
          return "grey darken-1";
        case 6:
          return "blue";
        case 7:
          return "pink";
        case 8:
          return "steal";
        default:
          return "black";
      }
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.selectedDate = this.moment().format(YYYYMMDD);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getDistanceByTechnician(tech) {
      return tech.Distance != undefined ? tech.Distance.toFixed(1) : 0;
    },
    getDurationByTechnician(tech) {
      return tech.MovingDuration != undefined
        ? tech.MovingDuration.toFixed(1)
        : 0;
    },
    setSelectedCase(selectedCase) {
      this.selectedCase = selectedCase;
    },
    getCasesToDispatch() {
      const urlParams = this.$route.query;
      this.branch = urlParams.branch ?? "";

      this.casesToDispatch = [];

      const scheduleListUrl = `https://sc-apiv2.vietmap.io/cases/schedulelist?branch=${this.selectedBranch}&searchText=${this.searchText}`;

      this.axios
        .get(
          this.selectedBranch
            ? scheduleListUrl
            : `https://sc-apiv2.vietmap.io/cases/schedulelist?branch=${this.branch}&searchText=${this.searchText}`
        )
        .then((response) => {
          // console.log(response);
          // let obj = JSON.parse(response.data.data.items);
          let obj = response.data.data.items;

          this.casesToDispatch = obj;
        });
    },
    getEventByDate() {
      if (this.selectedIndex === undefined || this.selectedCase === null) {
        this.showMessage("Vui lòng chọn case để điều phối");
        return;
      }

      this.loadingTechnician = true;
      // if (this.selectedDate !== this.moment().format(YYYYMMDD)) {
      //   this.LocationType = 2;
      // } else {
      //   this.LocationType = 1;
      // }

      this.branch = this.selectedCase.branch;
      // let url = `https://personal-qt5gqxyo.outsystemscloud.com/SCService/rest/case/Schedule?CasesId=${this.selectedCase.CaseId}&LocationType=1&Date=${this.selectedDate}`;
      let url = `https://sc-apiv2.vietmap.io/cases/schedule?CasesId=${this.selectedCase.id}&Date=${this.selectedDate}&branch=${this.branch}`;
      // console.log(url);
      this.axios
        .get(url)
        .then((response) => {
          // console.log(response);
          // if (!response.data.Success) {
          //   alert(response.data.Message);
          //   this.loadingTechnician = false;
          //   return;
          // }
          if (response.data.message !== null) {
            alert(response.data.message);
            this.loadingTechnician = false;
            return;
          }
          this.events = [];
          this.technicians = [];
          this.categories = [];

          // let res = JSON.parse(response.data.data.items);
          let res = response.data.data.items;
          // console.log(res);
          let activeCases = res.filter((item) => {
            if (item.cases.length > 0) return item;
            // console.log("🚀 ~ file: res:", res);
          });

          let inactiveCases = res.filter((inactiveItem) => {
            if (inactiveItem.cases.length === 0) return inactiveItem;
          });

          res = [...activeCases, ...inactiveCases];

          this.categories = res.map((element) => this.getCategoryText(element));

          res.forEach((element) => {
            this.technicians.push({
              Id: element.technicianId,
              Email: element.technicianEmail,
              CategoryName: this.getCategoryText(element),
            });
          });
          // console.log(this.technicians);

          res.forEach((technician) => {
            if (technician.cases.length > 0) {
              technician.cases.forEach((c) => {
                this.events.push({
                  name: c.code,
                  // timed: this.moment(c.planTime).format(YYYYMMDDHHmm),
                  // start: this.moment(c.planTime).format(YYYYMMDDHHmm),
                  // end: this.moment(c.finishTime).format(YYYYMMDDHHmm),
                  timed: this.moment
                    .utc(c.planTime)
                    .local()
                    .format(YYYYMMDDHHmm),
                  start: this.moment
                    .utc(c.planTime)
                    .local()
                    .format(YYYYMMDDHHmm),
                  end: this.moment
                    .utc(c.finishTime)
                    .local()
                    .format(YYYYMMDDHHmm),
                  color: this.getColorByCaseStatus(c),
                  category: this.getCategoryText(technician),
                });
              });
            }
          });
          this.loadingTechnician = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingTechnician = false;
        });
    },
    categoryOnClick(e) {
      if (e.category === undefined || this.selectedIndex === undefined) {
        this.showMessage("Vui lòng chọn case và tìm kỹ thuật phù hợp");
        return;
      }

      console.log(e);
      this.selectedCategoryEvent = e;
      this.showDispatchDialog = true;

      this.planTime = `${e.date} ${e.hour}:${e.minute}`;
      let min = e.minute;
      if (min <= 15) {
        this.planTime = `${e.date} ${e.hour}:00`;
      } else if (min > 15 && min <= 45) {
        this.planTime = `${e.date} ${e.hour}:30`;
      } else {
        this.planTime = `${e.date} ${e.hour}:45`;
      }
      // console.log(this.planTime);
    },
    assignCase() {
      let url = "https://sc-apiv2.vietmap.io/cases/assign";
      let getEmailParam = this.$route.query.email;
      let obj = {
        planTime: this.moment(this.planTime),
        caseId: this.selectedCase.id,
        technician: this.technicians.filter(
          (a) => this.selectedCategoryEvent.category == a.CategoryName
        )[0].Id,
        actionBy: `${getEmailParam}`,
      };

      console.log(obj.technician);
      this.loadingAssign = true;

      this.axios
        .post(url, obj)
        .then((res) => {
          console.log(res);
          this.showMessage("Điều phối thành công");
          this.getCasesToDispatch();
          this.loadingAssign = false;
          this.showDispatchDialog = false;
          this.technicians = [];
          this.events = [];
          this.categories = [];
          this.selectedIndex = undefined;
        })
        .catch((err) => {
          console.log(err);
          this.showMessage("Có lỗi xảy ra, vui lòng thử lại");
          this.loadingAssign = false;
        });
    },
  },
};
</script>

<style>
.header {
  margin-top: 5px;
  font-size: 18px !important;
  font-weight: bolder !important;
}

.dropdown-menu {
  padding: 5px 10px;
  width: 130px;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid #b3b3b3;
  border-radius: 7px;
  outline: none;
  color: rgb(102, 102, 102);
}

.arrow {
  /* position: absolute; */
  margin-left: -20px;
}

.dispatch-item {
  margin-left: 10px;
  margin-top: 5px;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
}

.theme--light.v-calendar-daily .v-calendar-daily_head-day {
  background: white !important;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  background: white !important;
}

.case-list {
  padding-left: 8px;
}

.v-calendar-category .v-calendar-daily_head-weekday {
  display: none !important;
}

.v-calendar-category .v-calendar-daily_head-day-label {
  display: none !important;
}

.v-menu__content {
  overflow-y: hidden !important;
}

.sc-date-picker {
  padding: 12px !important;
}

.loading-card {
  padding: 12px !important;
}

.ps {
  margin-left: 10px;
  padding-right: 7px;
  /* border: 1px solid #b3b3b3; */
  max-height: 660px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 4px #b3b3b3 inset;
}

.v-calendar-category .v-calendar-daily__day {
  min-width: 120px !important;
}

.v-calendar-category
  .v-calendar-category__columns
  .v-calendar-category__column-header {
  min-width: 120px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 8px;
  display: none;
}
</style>
